@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.google.com/download?family=Open%20Sans");

body {
	overflow: hidden;
}

strong {
	@apply font-bold;
}
